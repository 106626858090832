export const breadcrumb = [
  {
    href: '#',
    title: 'Blog',
    isActive: false,
  },
  {
    title: 'Blog Search',
    isActive: true,
  },
];

export const result = [
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg 2x',
    },
    title: 'Simple approach to Australian cafe',
    subtitle:
      'Independent holistic & healthy café | Simple approach to Australian cafe culture in a relaxed environment',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
      },
      name: 'Veronica Adams',
    },
    date: '04 Aug',
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg 2x',
    },
    title: 'Adidas will release your favourite shoes',
    subtitle:
      'Get your favourite adidas shoes, clothing & accessories at the official website! adidas Training.',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
      },
      name: 'Akachi Luccini',
    },
    date: '04 Aug',
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg 2x',
    },
    title: 'NIKE Online Store launches the website‎',
    subtitle:
      'Be Your Best Every Time With Nike Shoes And Clothing. Shop Online. The Official Website. Home Of Everything Nike. Shop The Latest Releases Today! Types: Shoes, Tops.',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
      },
      name: 'Jack Smith',
    },
    date: '04 Aug',
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg 2x',
    },
    title: "LARQ | World's First Self-cleaning Water Bottle‎",
    subtitle:
      "A self-cleaning water bottle that'll help you reach your hydration goal. Neutralizes up to 99%* of harmful, odor-causing bacteria using UV-C light.",
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/kate-segelson@2x.jpg 2x',
      },
      name: 'Kate Segelson',
    },
    date: '04 Aug',
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg 2x',
    },
    title: 'Simple approach to Australian cafe',
    subtitle:
      'Independent holistic & healthy café | Simple approach to Australian cafe culture in a relaxed environment',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
      },
      name: 'Veronica Adams',
    },
    date: '04 Aug',
  },
  {
    cover: {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover2.jpg 2x',
    },
    title: 'Adidas will release your favourite shoes',
    subtitle:
      'Get your favourite adidas shoes, clothing & accessories at the official website! adidas Training.',
    author: {
      photo: {
        src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
        srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
      },
      name: 'Akachi Luccini',
    },
    date: '04 Aug',
  },
];
